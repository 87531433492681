
import {
  defineComponent, ref
} from 'vue'
import ServicesApiKeysButtons from '@/components/pages/services/api/keys/ServicesApiKeysButtons.vue'
import ServicesApiKeysTable from '@/components/pages/services/api/keys/ServicesApiKeysTable.vue'
import ServicesApiV2Alert from '@/components/pages/services/api/ServicesApiV2Alert.vue'
import ServicesApiKeysEmpty from '@/components/pages/services/api/keys/ServicesApiKeysEmpty.vue'
import { getTableData } from '@/services/tableService'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ServicesApiKeys } from '@/definitions/services/api/types'

export default defineComponent({
  components: {
    ServicesApiV2Alert,
    ServicesApiKeysTable,
    ServicesApiKeysButtons,
    ServicesApiKeysEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'App name', value: 'appName' },
      { text: 'Username', value: 'username' },
      { text: 'API key', value: 'api-key-slot' },
      { text: 'Last used', value: 'last-used-slot' },
    ])

    const tableItems = getTableData('apiKeys') as ServicesApiKeys[]

    return {
      tableHeaders,
      tableItems,
      isEmptyMode,
    }
  },
})
