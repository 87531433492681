
import {
  defineComponent
} from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import useApiOpenModal from '@/compositions/services/useApiOpenModal'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'

export default defineComponent({
  components: {
    TmButton,
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
  },
  setup() {
    const { openGenerateNewApiKey } = useApiOpenModal()

    return {
      openGenerateNewApiKey,
    }
  },
})
