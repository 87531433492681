
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmTextCopy from '@/components/shared/TmTextCopy.vue'
import ServicesApiKeysDropdown from '@/components/pages/services/api/keys/ServicesApiKeysDropdown.vue'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ServicesApiKeys } from '@/definitions/services/api/types'

export default defineComponent({
  components: {
    TmTable,
    TmTextCopy,
    ServicesApiKeysDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<ServicesApiKeys[]>,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
})
