
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useApiOpenModal from '@/compositions/services/useApiOpenModal'

export default defineComponent({
  components: {
    TmButton,
    TmEmptyState,
  },
  setup() {
    const { openGenerateNewApiKey } = useApiOpenModal()

    return {
      openGenerateNewApiKey,
    }
  },
})
