import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_services_api_v2_alert = _resolveComponent("services-api-v2-alert")!
  const _component_services_api_keys_buttons = _resolveComponent("services-api-keys-buttons")!
  const _component_services_api_keys_table = _resolveComponent("services-api-keys-table")!
  const _component_services_api_keys_empty = _resolveComponent("services-api-keys-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_services_api_v2_alert),
          _createVNode(_component_services_api_keys_buttons, { class: "mb-4" }),
          _createVNode(_component_services_api_keys_table, {
            headers: _ctx.tableHeaders,
            items: _ctx.tableItems,
            "items-name": "keys"
          }, null, 8, ["headers", "items"])
        ], 64))
      : (_openBlock(), _createBlock(_component_services_api_keys_empty, { key: 1 }))
  ]))
}